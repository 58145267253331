@function btn-transition($property) {
    @return ($property $micro-animatics-duration $cubic-bezier-curve $micro-animatics-delay);
}

// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: rem(48px);
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    color: $body-color;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    text-transform: capitalize;
    text-decoration: none;
    @include button-size(0, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    @include transition(btn-transition(background-color), btn-transition(color), btn-transition(border-color));

    /* Safari only */
    @supports (-webkit-hyphens: none) {
        font-weight: $font-weight-normal;
    }

    @include hover {
        color: $black;
        background-color: $white;
        text-decoration: none;
    }

    &:focus-visible { // stylelint-disable-line
        outline: 2px solid $outline;
        outline-offset: 2px;
        text-decoration: none;

        &.dark-mode {
            outline-color: $outline-dark-mode;
        }
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        color: $btn-disabled-main-color;
        background-color: $btn-disabled-main-bg;
        border: none;

        &.dark-mode {
            background-color: $grey;
        }
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
    .btn-#{$color}:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(.asset-button) {
        @include button-variant($value, $value);
    }
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color}:not(:disabled):not(.disabled) {
        @include button-outline-variant($value);
    }
}

.btn-primary {
    color: $btn-primary-color;
    background-color: $btn-primay-bg;
    border: none;
    min-width: rem(100px);

    &:not(:disabled):not(.disabled) {
        &.dark-mode {
            color: $btn-primary-color-dark;
            background-color: $btn-primay-bg-dark;
        }

        &:hover {
            color: $btn-primary-color-hover;
            background-color: $btn-primay-bg-hover;

            &.dark-mode {
                color: $btn-primary-color-dark;
                background-color: $btn-primay-bg-hover-dark;
            }
        }

        &:active,
        &.active {
            color: $btn-primary-color;
            background-color: $btn-primary-bg-active;

            &.dark-mode {
                color: $btn-primary-color-dark;
                background-color: $btn-primary-bg-active-dark;
            }
        }
    }
}

.btn-secondary,
.btn-tertiary {
    &:not(:disabled):not(.disabled) {
        color: $black;
        background-color: transparent;
        border-color: $black;
        min-width: rem(100px);

        &.dark-mode {
            color: $white;
            border-color: $white;
        }

        &:hover {
            color: $black;
            background-color: $light-shades;

            &.dark-mode {
                border-color: $black;
            }
        }

        &:active,
        &.active,
        &:focus,
        &.focus {
            color: $black;
            background-color: $white;

            &.dark-mode {
                border-color: $black;
            }
        }
    }
}

.btn.btn-tertiary {
    height: rem(36px);
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    color: $link-color;
    text-decoration: $link-decoration-alt;
    padding: 0;
    font-size: rem(14px);
    font-family: $font-avenir-next-regular;
    line-height: rem(20px);
    letter-spacing: 0.02em;

    @include hover {
        color: $brand-second;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
    }

    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        box-shadow: none;
    }

    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
        background-color: transparent;
        border: 0;
    }
}

//
// Button Sizes
//

.btn-lg {
    @include button-size(0, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
    @include button-size(0, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

//
// Block button
//

.btn-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}

.btn-icon {
    display: flex;
    justify-content: center;
    align-items: baseline;

    svg {
        max-height: 14px;
    }
}

.btn-clear {
    background-color: transparent;
    border: none;
    padding: 0;
    line-height: normal;

    &:hover {
        text-decoration: none;
    }
}

button:not(:disabled) {
    &:focus:not(:focus-visible) { // stylelint-disable-line
        outline: 0;
    }

    &:focus-visible { // stylelint-disable-line
        outline: 2px solid $outline;
        outline-offset: 2px;
    }
}
